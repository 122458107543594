@use 'styles/config' as *;

.index {
  &__segment {
    @include segment;
  }

  &__container {
    @include container;
  }

  &__header {
    display: grid;
    align-items: flex-end;
    column-gap: 3rem;
    row-gap: responsive(3rem, 6rem);
    grid-template-columns: 1fr;
    margin-bottom: responsive(6rem, 8rem);

    @media (min-width: $min-840) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__headlines {
    grid-column: 1 / 2;
  }

  &__actions {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 3rem;
    grid-column: 1 / 2;

    @media (min-width: $min-840) {
      max-width: 50vw;
      justify-content: flex-end;
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }
  }

  &__content {
    grid-column: 1 / 2;
    grid-row: 2 / 3;

    display: flex;
    gap: 3rem;
    flex-wrap: wrap;
    // flex-direction: column;

    @media (min-width: $min-840) {
      grid-column: 1 / 3;
      grid-row: 2 / 3;

      // flex-direction: row;

      justify-content: space-between;
    }
  }

  &__text {
    @include render-copy;

    @media (min-width: $min-840) {
      flex: 1 1 auto;
      max-width: 67.5rem;

      .hasImage & {
        max-width: min(67.5rem, 50vw);
      }
    }

    .simple & {
      column-width: 35rem;
      gap: 3rem;
      max-width: 106rem;
    }
  }

  &__image {
    @include reset-all;
    width: 100%;

    @media (min-width: $min-840) {
      flex: 1 1 auto;
      width: auto;
      max-width: 56rem;
    }
  }

  &__tagline {
    @include taglineWithLine;

    margin: 0 0 4rem;
  }

  &__title {
    @include h1;
    margin: 0;

    :global(body.dark) & {
      color: #fff;
    }
  }

  &__button {
    @include reset-anchor();
    @include reset-button();
    position: relative;
    font-size: responsive(1.4rem, 1.6rem);
    font-weight: 600;
    // opacity: 0.7;
    // text-transform: uppercase;
    color: $color-font;
    cursor: pointer;
    letter-spacing: 0.02em;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1.5rem;
      height: 0.2rem;
      background-color: transparent;
      pointer-events: none;
    }

    &:is(.active, :hover, :focus-visible) {
      opacity: 1;
      color: $color-font;

      &::after {
        @include brandColor(background-color);
      }
    }
  }

  &__grid {
    display: grid;
    gap: responsive(2rem, 3rem);
    grid-template-columns: repeat(auto-fill, minmax(min(24rem, 100%), 1fr));
  }

  &__list {
    @include reset-list;
  }

  &__empty {
    padding: 5rem;
    font-weight: 400;
    color: $color-copy;
    text-align: center;
  }

  &__dropdown {
    > * {
      margin-bottom: 0 !important;
    }
  }

  &__footnote {
    margin-top: 3rem;

    p {
      @include copy(1.2rem, 1.4rem);
    }
  }
}

.item {
  position: relative;
  overflow: hidden;
  max-width: 42rem;
  box-shadow: 0 2rem 7.7rem rgba(#000, 0.07);
  background-color: #fff;
  color: $color-font;

  &__link {
    @include reset-anchor;
  }

  &__layout {
    display: grid;
    height: 100%;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto 1fr 0;
  }

  &__image {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: responsive(1.5rem, 3rem) responsive(1.5rem, 3rem) 0;
  }

  &__aspect {
    position: relative;
    width: 100%;
    max-height: 100%;
    aspect-ratio: 1 / 1;
    transition: max-height $transition-leave;

    @supports not (aspect-ratio: 1 / 1) {
      &::before {
        float: left;
        padding-top: 100%;
        content: '';
      }

      &::after {
        display: block;
        content: '';
        clear: both;
      }
    }
  }

  // &:is(:hover, :focus-visible) &__aspect {
  //   max-height: calc(100% - 10rem);
  //   transition: max-height $transition-enter;
  // }

  &__content {
    padding: responsive(1.5rem, 3rem);
    transform: translateY(0);
    transition: $transition-leave;
    background: rgba(#fff, 0.9);
    backdrop-filter: blur(10px);

    :global(.dark-theme) &,
    :global(body.dark) & {
      background: rgba(#232323, 0.9);
    }

    :global(body.brand) & {
      background: brand(0.85);
    }
  }

  &:is(:hover, :focus-within) &__content {
    transform: translateY(-10rem);
    transition: transform $transition-enter;
    box-shadow: 0 0 1rem rgba(#000, 0.1);
  }

  &__title {
    @include tagline;
    margin-bottom: 0.7rem;
    max-width: 100%;
    overflow: hidden;

    &:not(.simple) & {
      @include trailingLine(1.8rem);
    }

    :global(.dark-theme) & {
      color: #fff;
    }
  }

  &__copy {
    @include render-copy;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__note {
    @include copy(1.2rem, 1.2rem);
    margin-top: 1rem;
    color: $color-copy;

    b {
      font-weight: 700;
      color: $color-copy;
    }
  }

  &__button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 3rem 4.5rem;
    height: 10rem;
    font-size: 1.2rem;
    letter-spacing: 0.08em;
    font-weight: 700;
    font-family: $font-family;
    text-transform: uppercase;
    background: brand();
    transform: translateY(100%);
    transition: $transition-leave;

    svg {
      width: 1.1rem;
      height: 1.8rem;
    }

    :global(body.brand) & {
      background: $color-dark;
      color: #fff;

      svg path {
        fill: #fff;
      }
    }

    :global(.almatica) & {
      color: #fff;

      svg path {
        fill: #fff;
      }
    }
  }

  &:is(:hover, :focus-within) &__button {
    transform: translateY(0);
    transition: $transition-enter;
  }
}

.listItem {
  position: relative;
  display: block;
  border-top: 1px solid $color-border;
  transition: background 200ms, color 200ms;

  &:last-child {
    border-bottom: 1px solid $color-border;
  }

  &:is(:hover, :focus-visible) {
    color: $color-dark;
    background-color: brand();
  }

  &__link {
    @include reset-anchor;
  }

  &__layout {
    display: flex;
    align-items: center;
    gap: responsive(1rem, 3rem);
    padding: 1rem responsive(1rem, 3rem);
  }

  &__title {
    @include reset-heading;
    font-size: responsive(1.5rem, 1.8rem);
    font-weight: 600;
    line-height: 1.6;
  }

  &__text {
    @include copy(1.4rem, 1.6rem);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 72rem;
    line-height: 1.4;
  }

  &:hover &__text {
    color: $color-dark;
  }

  &__image {
    flex: 0 0 auto;
    position: relative;
    width: responsive(6rem, 8rem);
    height: responsive(6rem, 8rem);
  }
}

.dropdown {
  position: relative;
  font-size: responsive(1.4rem, 1.6rem);
  font-weight: 600;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    margin: auto;

    height: 0;
    width: 0;

    border: 0.5rem solid transparent;
    border-top-width: 0.6rem;
    border-top-color: $color-dark;

    pointer-events: none;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1.5rem;
    height: 0.1rem;
    background-color: $color-border-form;
    opacity: 1;
    pointer-events: none;
  }

  &:hover::before {
    height: 0.2rem;
    background-color: brand();
  }

  select {
    @include reset-input;
    letter-spacing: 0.03em;
    padding-right: 2.5ch;
    // text-transform: uppercase;
  }

  select::-ms-expand {
    display: none;
  }
}
