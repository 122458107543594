@use 'styles/config' as *;
@use "sass:math";

.jobListing {
  &__segment {
    @include segment();
  }

  &__container {
    @include container();
  }

  &__row {
    @include grid-row();
  }

  &__col {
    @include grid-col();
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 8rem;

    @media (min-width: $min-720) {
      margin-bottom: 12rem;
    }

    @media (min-width: $min-1080) {
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  &__heading {
    margin-bottom: 4rem;

    @media (min-width: $min-1080) {
      margin-bottom: 0;
    }
  }

  &__title {
    @include h2();
    max-width: 64rem;

    :global(body.dark) & {
      color: #fff;
    }
  }

  &__search {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    // border-bottom: 1px solid #000;

    @media (min-width: $min-1080) {
      justify-content: flex-start;
    }
  }

  &__options {
    &List {
      @include reset-list;
      display: flex;
    }

    &Item {
      cursor: pointer;
      text-transform: uppercase;
      font-size: responsive(1.2rem, 1.4rem);
      letter-spacing: math.div(0.58, 14) * 1em;
      font-weight: 700;
      color: $color-copy;
      margin-right: 2rem;
      padding-bottom: 1.5rem;
      border-bottom: 0.2rem solid transparent;
      white-space: nowrap;

      @media (min-width: $min-720) {
        margin-right: 4rem;
      }

      @media (min-width: $min-1200) {
        margin-right: 6rem;
      }

      &.active {
        @include brandColor(border-bottom-color);
        color: $color-dark;
      }
    }
  }

  .focus &__options,
  &__header:focus-within &__options {
    @media (max-width: $max-600) {
      display: none;
    }
  }

  &__filter {
    position: relative;
    padding-bottom: 1.2rem;
    border-bottom: 0.2rem solid transparent;

    &Input {
      @include reset-input;
      padding-left: 2.1rem;
      width: 2.1rem;
      height: 3rem;
      font-size: 1.4rem;
      font-weight: 600;
      // background: #ccc;

      @media (min-width: $min-600) {
        transition-property: width, padding;
        transition-duration: 300ms;
      }
    }

    .focus &Input,
    &:focus-within &Input {
      padding-left: 3rem;
      width: 100%;

      @media (min-width: $min-600) {
        width: 20rem;
      }
    }

    &Icon {
      position: absolute;
      top: 0.7rem;
      left: 0;
      pointer-events: none;
    }

    svg {
      display: block;
      width: 2.1rem;
      height: 2.1rem;

      path {
        stroke: $color-copy;
      }
    }

    .focus &,
    &:focus-within {
      width: 100%;
      @include brandColor(border-bottom-color);

      @media (min-width: $min-600) {
        width: auto;
      }

      svg path {
        stroke: $color-dark;
      }
    }
  }

  &__list {
    display: grid;
    grid-column-gap: 6rem;
    grid-row-gap: 6rem;
    grid-auto-flow: row;
    grid-template-columns: 1fr;

    @media (min-width: $min-840) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (min-width: $min-1080) {
      grid-column-gap: 4rem;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}

.jobListingItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid $color-border;
  padding-bottom: 4rem;

  &__content {
    flex: 0 1 auto;
    width: 100%;
  }

  &__tagline {
    @include tagline;
    @include trailingLine(5.5rem, 'dark');
    margin-bottom: 1.5rem;
  }

  &__title {
    @include h3();
    margin-bottom: 3rem;
  }

  &__copy {
    @include copy();
    margin-bottom: 4rem;
  }
}
