@import '~styles/config';

.shareholder {
  &__segment {
    @include segment;
  }

  &__container {
    @include container;
  }

  &__header {
    display: grid;
    align-items: flex-end;
    column-gap: 3rem;
    row-gap: responsive(3rem, 6rem);
    grid-template-columns: 1fr;
    margin-bottom: responsive(6rem, 8rem);

    @media (min-width: $min-840) {
      grid-template-columns: 1fr auto;
    }
  }

  &__headlines {
    grid-column: 1 / 2;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    column-gap: responsive(2rem, 5rem);
    row-gap: 3rem;
    grid-column: 1 / 2;

    @media (min-width: $min-840) {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }
  }

  &__button {
    @include reset-button();
    position: relative;
    font-size: responsive(1.2rem, 1.4rem);
    font-weight: 700;
    opacity: 0.7;
    text-transform: uppercase;
    color: #3a3b39;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1.5rem;
      height: 0.2rem;
      background-color: transparent;
      pointer-events: none;
    }

    &:is(.active, :hover, :focus-visible) {
      opacity: 1;
      color: $color-font;

      &::after {
        @include brandColor(background-color);
      }
    }
  }

  &__text {
    @include render-copy;
    max-width: 67.5rem;

    grid-column: 1 / 2;
    grid-row: 2 / 3;

    @media (min-width: $min-840) {
      grid-column: 1 / 3;
      grid-row: 2 / 3;
    }
  }

  &__tagline {
    @include taglineWithLine;

    margin: 0 0 4rem;
  }

  &__title {
    @include h1;
    margin: 0;
    max-width: 67.5rem;

    :global(body.dark) & {
      color: #fff;
    }
  }

  &__grid {
    display: block;
    box-shadow: 0 2rem 7.7rem rgba(#000, 0.07);
  }

  &__reports {
    padding: responsive(2rem, 7rem) responsive(2rem, 5rem);
    box-shadow: 0 2rem 7.7rem rgba(#000, 0.07);

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(40rem, 100%), 1fr));
  }
}

.year {
  padding: responsive(1.5rem, 3rem) responsive(1.5rem, 2.4rem);

  &:not(:last-child) {
    border-bottom: 1px solid $color-border;
  }

  &__title {
    @include tagline;
    @include trailingLine(2rem);
    letter-spacing: 1px;
    color: $color-copy;
    margin-bottom: responsive(1rem, 2rem);
  }

  &__grid {
    display: grid;
    column-gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(min(30rem, 100%), 1fr));
  }
}

.report {
  @include copy();

  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;

  &__icon {
    flex: 0 0 auto;
    display: block;
    width: 2.2rem;
    height: 2.2rem;
    margin-right: 2.5rem;

    :global(body.dark) & {
      svg path {
        stroke: #fff;
      }
    }
  }

  &__link {
    @include reset-anchor();
    display: flex;
    align-items: center;
    color: $color-dark;
    letter-spacing: 0.1rem;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    transition: color $transition-enter;

    :global(body.dark) & {
      color: #fff;
    }

    &:hover {
      @include brandColor(color);

      :global(body.dark) & {
        @include brandColor(color);
      }

      :global(body.brand) & {
        color: #fff;
      }
    }
  }
}
