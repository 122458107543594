@use 'styles/config' as *;
@use "sass:math";

.articles {
  &:not(.flush) &__segment {
    @include segment();
  }

  &.flush &__segment {
    @include segment-bottom();
    @include nav-offset-margin;
  }

  &__container {
    @include container();
  }

  &__row {
    @include grid-row();
  }

  &__col {
    @include grid-col();
  }

  &__header {
    margin-bottom: 4rem;

    @media (min-width: $min-720) {
      display: flex;
      margin-bottom: 8rem;

      .col-1 & {
        margin-bottom: 6rem;
      }
    }
  }

  &__text {
    @media (min-width: $min-720) {
      flex: 0 0 50%;
      // max-width: 65.5rem;
      padding-right: 5rem;
    }
  }

  &__tagline {
    @include taglineWithLine;

    margin: 0 0 4rem;
  }

  &__title {
    @include h1;

    :global(body.dark) & {
      color: #fff;
    }
  }

  &__opFade {
    flex: 0 0 50%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    > div {
      width: 100%;
      display: flex;
      align-items: flex-end;
    }
  }

  &__options {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 3rem;
    margin-bottom: 1rem;

    @media (min-width: $min-720) {
      margin-top: 0;
      margin-bottom: 0;
      justify-content: flex-end;
    }
  }

  &__select {
    @include formInput();
    padding-right: 3rem;
  }

  &__dropdown {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0.5rem;

      margin: auto;

      height: 0;
      width: 0;

      border: 0.5rem solid transparent;
      border-top-width: 0.6rem;
      border-top-color: $color-dark;

      :global(body.dark) & {
        border-top-color: #fff;
      }
    }

    select::-ms-expand {
      display: none;
    }
  }

  &__filter {
    margin-bottom: 6rem;

    @media (min-width: $min-720) {
      margin-bottom: 10rem;
    }

    &List {
      @include reset-list();

      @include supports-grid {
        display: grid;
        grid-gap: 0.1rem;
        grid-template-columns: repeat(7, minmax(0, 1fr));
        overflow: hidden;

        box-shadow: 0 3rem 2rem 0 rgba(#000, 0.05), 0 0 1rem 0 rgba(#000, 0.025);

        @media (min-width: $min-420) {
          grid-template-columns: repeat(7, minmax(0, 1fr));
        }

        @media (min-width: $min-560) {
          grid-template-columns: repeat(9, minmax(0, 1fr));
        }

        @media (min-width: $min-720) {
          grid-template-columns: repeat(13, minmax(0, 1fr));
        }
      }

      @include no-supports-grid {
        display: flex;
        flex-wrap: wrap;

        > * {
          width: math.div(100%, 13);
        }
      }
    }

    &Item {
      position: relative;
      box-shadow: 0 0 0 1px $color-border;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
      }

      > * {
        position: absolute 0;
      }
    }

    &Button {
      @include reset-button();
      position: absolute 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      font-size: responsive(1.2rem, 1.4rem);
      font-weight: 700;
      text-transform: uppercase;

      &.active {
        background: $color-border;
      }

      &:hover {
        @include brandColor(background-color);
      }
    }
  }

  &__footer {
    margin-top: $gutter;
  }

  &__list {
    @include reset-list;

    .col-1 & {
      display: flex;
      flex-wrap: wrap;
    }

    .col-3-image & {
      display: grid;
      grid-column-gap: $gutter;

      @media (min-width: $min-720) {
        grid-column-gap: $gutter * 2;
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @media (min-width: $min-1200) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }
  }
}

.item {
  @include reset-anchor();

  margin-bottom: 4rem;

  @media (min-width: $min-960) {
    margin-bottom: $gutter * 2;
  }

  &.col-1 {
    &:not(.featured) {
      @media (min-width: $min-960) {
        margin-left: percentage(math.div(2, 12));
        margin-right: percentage(math.div(2, 12));
      }
    }
  }

  .col-1 &.featured {
    @media (min-width: $min-960) {
      width: 50%;
      order: -1;
    }
  }

  .col-3-image &.featured {
    @media (min-width: $min-720) {
      grid-column: span 2;
    }

    @media (min-width: $min-1200) {
      grid-column: span 3;
    }
  }

  &__link {
    @include reset-anchor;
  }

  &__layout {
    .col-3-image .featured & {
      display: grid;

      @media (min-width: $min-720) {
        grid-column-gap: $gutter * 2;
        grid-template-columns: 1fr 1fr;
      }

      @media (min-width: $min-1200) {
        grid-template-columns: 6fr 4fr;
      }
    }
  }

  &__image {
    position: relative;
    margin-bottom: 2rem;
    overflow: hidden;

    @media (min-width: $min-720) {
      margin-bottom: 4rem;
    }

    &::after {
      content: '';
      position: absolute 0;
      pointer-events: none;
      box-shadow: inset 0 0 3rem 0 rgba(#000, 0.05);
    }

    &.featured {
      @media (min-width: $min-720) {
        margin-bottom: 0;
      }
    }
  }

  &__aspect {
    width: 100%;
    height: 0;
    padding-bottom: 70%;
  }

  &__img {
    position: absolute 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition-property: transform;
    transition-duration: 1400ms;
  }

  &:focus &__img,
  &:hover &__img {
    transform: scale(1.15);
    transition-duration: 600ms;
  }

  &__header {
    display: flex;
  }

  &__text {
    display: flex;
    flex-direction: column;

    @media (min-width: $min-480) {
      padding: 0 0 0 4rem;

      .col-1 & {
        padding: 0;
      }
    }

    .featured & {
      @media (min-width: $min-720) {
        padding: 0;
      }
    }
  }

  &__tagline {
    @include tagline;
    @include trailingLine;
    margin-bottom: 1.2rem;

    .featured & {
      @media (min-width: $min-720) {
        margin-bottom: 2.2rem;
      }
    }
  }

  &__title {
    @include h3();
    margin-bottom: 2rem;
    letter-spacing: math.div(-0.93, 28) * 1em;

    transition-property: color, border;
    transition-duration: $transition-leave;

    :global(body.dark) & {
      color: #fff;
    }

    .col-1 & {
      @include h4();
      margin-bottom: 2rem;
      letter-spacing: math.div(-0.93, 28) * 1em;
    }

    .featured & {
      margin-bottom: 0;

      @media (min-width: $min-720) {
        @include h2(3rem, 4.5rem);
        // @include h2(3rem, 5.2rem);
        letter-spacing: math.div(-1.42, 52) * 1em;
        line-height: math.div(60, 52);
      }
    }

    &Link {
      @include reset-anchor;
    }
  }

  &:focus &__title,
  &:hover &__title {
    @include brandColor(color);

    transition-property: color, border;
    transition-duration: $transition-enter;
  }

  &__copy {
    @include render-copy;
    margin-top: 1.5rem;

    .col-1 & {
      margin: 0 0 2rem 0;
    }
    @media (min-width: $min-720) {
      margin-top: 4rem;

      .col-1.featured & {
        margin-top: 4rem;
      }
    }
  }

  &__date {
    @include tagline;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
  }
}

.articlesFilters {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10rem;

  &__tags {
    @include reset-list;

    display: flex;
    flex-wrap: wrap;
    height: 100%;
    margin-left: responsive(-1rem, -2.5rem);
    margin-bottom: responsive(2rem, 0rem);

    li {
      display: flex;
      margin: 0 responsive(1rem, 2.5rem);
    }
  }

  &__tag {
    @include reset-anchor;
    @include tagline;

    padding: 1rem 0;
    margin: 0;

    color: $color-copy;
    font-size: responsive(1.2rem, 1.4rem);

    border-bottom: 2px solid transparent;

    &.active {
      border-bottom-color: brand();
      color: $color-font;
    }
  }

  &__group {
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: $min-480) {
      flex-direction: row;
    }
  }

  &__range {
    position: relative;
    font-size: 1.2rem;
    margin-right: 3.4rem;

    &Fields {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      min-width: 35rem;
      padding: 1.5rem;
      background: $color-background;
      box-shadow: 0 3rem 3.5rem 0 rgba(#000, 0.1);

      .active & {
        display: flex;
      }
    }

    &Toggle {
      @include reset-button;
      @include tagline;

      display: flex;
      align-items: center;
      position: relative;
      margin: 0;
      padding: 1rem 4rem 1rem 0;
      height: 100%;
      font-size: responsive(1.2rem, 1.4rem);
      color: $color-copy;

      border-bottom: 1px solid $color-border;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 46%;
        width: 0;
        height: 0;
        border-left: 0.3rem solid transparent;
        border-right: 0.3rem solid transparent;

        border-top: 0.4rem solid $color-copy;

        transform: translate(0, -50%);

        .active & {
          transform: translate(0, -50%) rotate(180deg);
        }
      }

      &Icon {
        margin-right: 1rem;
      }
    }
  }

  &__search {
    display: flex;
    border-bottom: 1px solid $color-border;

    &Form {
      display: flex;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
    }

    &Input {
      @include reset-input;
      @include tagline;

      padding: 1rem 1rem 1rem 0;
      max-width: 20rem;
      font-size: responsive(1.2rem, 1.4rem);
    }

    &InputWrapper {
      position: relative;
      margin-right: 1.2rem;
    }

    &Clear {
      @include reset-button;

      position: absolute;
      right: 0;
      top: 0;
      padding: 0.5rem;

      cursor: pointer;

      opacity: 0.5;

      &:hover {
        opacity: 1;
      }

      &::after {
        content: '×';
      }
    }

    &Button {
      @include reset-button();
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.8rem;
      height: 1.8rem;
      opacity: 0.7;

      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }
}
