@use 'styles/config' as *;
@use "sass:math";

@mixin catalog-row {
  display: grid;
  gap: responsive(1rem, 3rem);
  grid-template-columns: minmax(0, 2fr) minmax(0, 2fr) minmax(0, 2fr) minmax(0, 1fr) 1.8rem;
}

.tableList {
  &__header {
  }

  &__table {
    display: grid;
  }

  &__thead,
  &__tbody {
    display: block;
  }

  &__header {
    display: none;

    @media (min-width: $min-720) {
      @include catalog-row;
      padding: 1rem responsive(0rem, 1.2rem) 3rem responsive(0rem, 3rem);
    }
  }

  &__label {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    min-height: 1.8rem;

    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
    letter-spacing: math.div(0.5, 12) * 1em;
    color: $color-font-light;

    user-select: none;
  }

  &__indicator {
    opacity: 0;
    width: 1.1rem;
    height: 1.8rem;

    svg {
      display: block;
      width: 1.1rem;
      height: 1.8rem;
    }

    .sorted & {
      opacity: 1;
      transform: rotate(90deg) scale(0.8);
    }

    .descending & {
      transform: rotate(-90deg) scale(0.8);
    }
  }

  &__empty {
    padding: 5rem;
    font-weight: 400;
    color: $color-copy;
    text-align: center;
  }
}

.item {
  position: relative;
  display: block;
  border-top: 1px solid $color-border;

  @media (max-width: $max-720) {
    margin-left: -$container-gutter-mobile;
    margin-right: -$container-gutter-mobile;
  }

  &:last-child {
    border-bottom: 1px solid $color-border;
  }

  &__wrap {
    display: block;
    padding: 0;
  }

  &__header {
    cursor: pointer;
    padding: 2rem 5rem 2rem 3rem;

    @media (min-width: $min-720) {
      padding: 0;
    }

    // &:is(:hover, :focus-visible) {
    //   background-color: #fafafa;
    // }

    @media (min-width: $min-720) {
      @include catalog-row;
      padding: 0 responsive(0rem, 1.2rem) 0 responsive(0rem, 3rem);
    }
  }

  &__value {
    font-size: 1.5rem;

    @media (min-width: $min-720) {
      display: flex;
      align-items: flex-start;
      padding: 2rem 0;
      font-size: responsive(1.3rem, 1.7rem);
    }

    &.name {
      font-weight: 600;

      &::before {
        display: none;
      }
    }

    &::before {
      content: attr(data-label);
      display: block;
      margin-top: 1rem;
      margin-right: 1rem;

      font-size: 0.8em;
      color: $color-copy;
    }

    &:last-child {
      justify-content: center;
      align-self: center;

      &::before {
        display: none;
      }
    }

    @media (min-width: $min-720) {
      &::before {
        display: none;
      }
    }
  }

  &__status {
    position: absolute;
    top: 2.2rem;
    right: $container-gutter-mobile;
    display: flex;
    width: 1.1rem;
    height: 1.8rem;
    transform: rotate(90deg);
    transform-origin: 50% 50%;

    @media (min-width: $min-720) {
      position: static;
    }

    svg {
      display: block;
      width: 1.1rem;
      height: 1.8rem;
    }

    .open & {
      transform: rotate(-90deg);
    }
  }

  // &__header:is(:hover, :focus-visible) &__status {
  //   svg path {
  //     fill: brand();
  //   }
  // }

  &__body {
    display: none;
    padding: 0 3rem responsive(2rem, 3rem);
    border-top: 1px solid $color-border;
    background-color: #fafafa;

    .open & {
      display: block;
    }
  }

  &__footer {
    overflow: hidden;
    display: flex;
    padding-top: responsive(2rem, 3rem);
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    gap: 3rem;
  }

  &__external {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: responsive(2rem, 3rem);
  }
}

.variants {
  padding-top: responsive(2rem, 3rem);
  padding-bottom: responsive(2rem, 3rem);
  width: 100%;
  border-bottom: 1px solid $color-border;

  &__header {
    padding-bottom: responsive(1rem, 3rem);
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: math.div(0.5, 12) * 1em;
    color: $color-font-light;
    text-align: left;
  }

  &__value {
    padding: responsive(0.3rem, 0.8rem) 0;
    text-align: left;
    vertical-align: middle;
    font-size: responsive(1.3rem, 1.7rem);
    font-weight: 600;
  }
}
