@use 'styles/config' as *;

.divider {
  @include segment($segment-padding * 0.75, $segment-padding-mobile);
  display: flex;
  justify-content: center;

  &__container {
    @include container();
    max-width: 100%;
  }

  &__wrapper {
    overflow: hidden;
    width: 0;
    margin: auto;
    border-radius: 5rem;
    transition: width 1s;
  }

  &__wrapper.visible {
    width: 100%;
  }

  &__image {
    display: block;
    width: 52rem;
    max-width: none;

    margin-left: 50%;
    transform: translateX(-50%);
  }
}
