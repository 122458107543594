@use 'styles/config' as *;

.quote {
  @include segment;
  background-color: #f0f0f0;

  & + & {
    padding-top: calc(#{$segment-padding} * -1);
    margin-top: responsive(-6rem, -13rem);
  }

  &.offsetTop {
    --offset-top: 5rem;
  }

  &.offsetBottom {
    --offset-bottom: 5rem;
  }

  &__container {
    @include container;
  }

  &__layout {
    display: grid;
    align-items: center;
    row-gap: responsive(3rem, 5rem);
    column-gap: 3rem;
    grid-template-areas: '.' 'aside' 'main' '.';

    grid-template-rows:
      0rem
      auto auto 0rem;

    @media (min-width: $min-720) {
      row-gap: 0;
      column-gap: 6rem;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-areas:
        '. .'
        '. .'
        'main aside'
        '. .'
        '. .';

      grid-template-rows:
        var(--offset-top, 0rem) responsive(0rem, $segment-padding * 0.5, 72rem, 128rem)
        auto
        responsive(0rem, $segment-padding * 0.5, 72rem, 128rem)
        var(--offset-bottom, 0rem);

      .left & {
        grid-template-areas:
          '. .'
          '. .'
          'aside main'
          '. .'
          '. .';
      }
    }

    @media (min-width: $min-960) {
      column-gap: 3rem;
      grid-template-areas:
        '. . . . . . . . . . . .'
        '. . . . . . . . . . . .'
        'main main main main main main . aside aside aside aside aside'
        '. . . . . . . . . . . .'
        '. . . . . . . . . . . .';
      grid-template-columns: repeat(12, minmax(0, 1fr));

      .left & {
        grid-template-areas:
          '. . . . . . . . . . . .'
          '. . . . . . . . . . . .'
          'aside aside aside aside aside . main main main main main main'
          '. . . . . . . . . . . .'
          '. . . . . . . . . . . .';
      }
    }
  }

  &__main {
    grid-area: main;
  }

  &__title {
    margin-bottom: responsive(2rem, 6rem);

    h1,
    h2,
    h3 {
      @include h2(3rem, 5.2rem);
    }
  }

  &__links {
    margin-top: responsive(3rem, 7rem);
  }

  &__aside {
    grid-area: aside;

    @media (min-width: $min-720) {
      .offsetTop & {
        grid-row-start: 2;
        align-self: flex-start;
      }

      .offsetBottom & {
        grid-row-end: -2;
        align-self: flex-end;
      }
    }
  }

  &__image {
    position: relative;
    aspect-ratio: 3/4;
  }
}
