@use 'styles/config' as *;

.faq {
  &__segment {
    @include segment();
  }

  &__container {
    @include container();
  }

  &__title {
    @include h2();
    margin-bottom: 2em;
  }

  &__row {
    @include grid-row();
  }

  &__col {
    @include grid-col(10);
    @include grid-offset-left(1);
  }

  &__question {
    @include h3();
    margin-bottom: 1.5rem;
  }

  &__answer {
    @include render-copy();
    margin: 0 0 $gutter $gutter;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
