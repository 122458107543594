@use 'styles/config' as *;

.hero {
  @include segment;

  &__container {
    @include container;
  }

  &__layout {
    // display: grid;
    // align-items: flex-end;
    // grid-template-areas: 'main' 'aside';
    // gap: 3rem;
    // row-gap: responsive(3rem, 7.5rem);

    @media (min-width: $min-840) {
      // grid-template-areas: 'main aside';
      // grid-template-columns: minmax(0, 7fr) minmax(0, 5fr);
    }

    @media (min-width: $min-1200) {
      // grid-template-columns: minmax(0, 6fr) minmax(0, 6fr);
    }
  }

  &__main {
    grid-area: main;
    margin-left: responsive(0rem, -6rem, 144rem, 160rem);
  }

  &__title {
    h1,
    h2,
    h3 {
      @include h1(3.2rem, 6.4rem);
    }
  }

  &__aside {
    display: grid;
    max-width: 100%;
    overflow: hidden;
    row-gap: responsive(4rem, 6rem);
    // gap: responsive(3rem, 6rem);
    grid-template-columns: repeat(12, minmax(0, 1fr));
    // margin-bottom: responsive(0rem, 3rem);

    @media (min-width: $min-840) {
      column-gap: $gutter;
    }
  }

  &__text {
    grid-column: 1 / -1;
    // margin-top: responsive(3rem, 7.5rem);
    // margin-bottom: responsive(3rem, 7.5rem);
    // max-width: 45rem;

    // @media (min-width: $min-720) {
    //   margin-left: 16.666%;
    //   margin-right: 16.666%;
    // }

    @media (min-width: $min-840) {
      grid-column: 1 / 8;
    }

    @media (min-width: $min-1280) {
      grid-column: 2 / 8;
    }
  }

  &__data {
    grid-column: 1 / -1;

    @media (min-width: $min-840) {
      grid-column: 9 / -1;
    }

    @media (min-width: $min-960) {
      grid-column: 9 / -1;
    }
  }

  &__links {
    margin-top: responsive(4rem, 9rem);
    margin-bottom: responsive(2rem, 5rem);
  }

  &__buttons {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(max(min(37rem, 100%), calc(30% - 1rem)), 1fr));
    column-gap: 3rem;
    row-gap: 2rem;
    align-items: flex-start;
    margin-top: responsive(3rem, 6rem);
    // max-width: 45rem;
  }
}
