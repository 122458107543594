@use 'styles/config' as *;
@use "sass:math";

.sales {
  &__table {
    width: 100%;

    @media (max-width: $max-1080) {
      display: block;
    }
  }

  &__thead,
  &__tbody {
    @media (max-width: $max-1080) {
      display: block;
    }
  }

  &__row {
    @media (max-width: $max-1080) {
      display: grid;
      column-gap: 15%;
      margin-bottom: 3rem;
      grid-template-columns: repeat(auto-fill, minmax(min(30rem, 100%), 1fr));
    }
  }

  &__headerRow {
    @media (max-width: $max-1080) {
      display: none;
    }
  }

  &__header {
    padding: 1rem 0 3rem responsive(1rem, 3rem, 96rem, 128rem);
    min-height: 1.8rem;

    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
    letter-spacing: math.div(0.5, 12) * 1em;
    color: $color-font-light;

    user-select: none;
  }

  &__cell {
    font-size: 1.5rem;

    @media (min-width: $min-1080) {
      border-top: 1px solid $color-border;
      padding: 1.5rem 0 1.5rem responsive(1rem, 3rem, 96rem, 128rem);
      font-size: responsive(1.3rem, 1.7rem);
    }

    @media (max-width: $max-1080) {
      display: flex;
      gap: 2rem;
      justify-content: space-between;
      line-height: 2.1rem;

      &.name {
        grid-column: 1 / -1;
        grid-row: 1 / 2;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        border-bottom: 1px solid $color-border;
      }

      &:not(.name)::before {
        content: attr(data-label);
        font-size: 1.2rem;
        font-weight: 700;
        text-transform: uppercase;
        text-align: left;
        letter-spacing: math.div(0.5, 12) * 1em;
        color: $color-font-light;
      }
    }

    &.name {
      font-weight: 600;
    }
  }

  &__row:last-child &__cell {
    @media (min-width: $min-1080) {
      border-bottom: 1px solid $color-border;
    }
  }

  &__caption {
    margin-bottom: responsive(0rem, 1.5rem);

    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: math.div(1, 12) * 1em;
    text-transform: uppercase;
    text-align: right;

    @media (max-width: $max-1080) {
      display: flex;
      justify-content: flex-end;
    }
  }
}
