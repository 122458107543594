@use 'styles/config' as *;
@use "sass:math";

.datePicker {
  $datepicker__background-color: $color-border;
  $datepicker__border-color: $color-border;
  $datepicker__highlighted-color: #3dcc4a;
  $datepicker__muted-color: $color-copy;
  $datepicker__selected-color: $color-orange;
  $datepicker__text-color: $color-font;
  $datepicker__header-color: $color-font;

  $datepicker__font-size: 1.4rem;
  $datepicker__item-size: 3.5rem;
  $datepicker__font-family: $font-family;
  $datepicker__border-radius: 0;

  $datepicker__navigation-size: 0.8rem;

  @import '~react-datepicker/src/stylesheets/datepicker-cssmodules';

  & + & {
    margin-left: 2rem;
  }

  &__label {
    @include tagline;

    font-size: 1.1rem;
    color: $color-copy;
  }

  input {
    @include reset-input;
    width: 100%;
    padding: 1rem;
    border: 0.1rem solid $color-border;
  }

  :global(.react-datepicker__close-icon) {
    display: flex;
    align-items: center;
    &::after {
      background: transparent;
      color: $color-copy;
      border-radius: 0;
      height: 2rem;
      width: 2rem;
      font-size: 2rem;
      line-height: 1.5rem;
    }
  }
}
