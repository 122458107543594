@use 'styles/config' as *;

.retailers {
  @include segment;

  &__container {
    @include container;
  }

  &__header {
    display: flex;
    flex-direction: column;
    // flex-direction: column;
    // flex-flow: wrap;
    gap: 3rem;
    // align-items: flex-end;
  }

  &__headlines {
  }

  &__tagline {
    @include taglineWithLine;
  }

  &__title {
    @include h2;

    :global(body.dark) & {
      color: #fff;
    }
  }

  &__text {
    @media (min-width: $min-720) {
      max-width: 84rem;
    }
  }

  &__copy {
    @include render-copy;
  }

  &__button {
    @include reset-button();
    position: relative;
    font-size: responsive(1.2rem, 1.4rem);
    font-weight: 700;
    opacity: 0.7;
    text-transform: uppercase;
    color: #3a3b39;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1.5rem;
      height: 0.2rem;
      background-color: transparent;
      pointer-events: none;
    }

    &:is(.active, :hover, :focus-visible) {
      opacity: 1;
      color: $color-font;

      &::after {
        @include brandColor(background-color);
      }
    }
  }

  &__grid {
    display: grid;
    gap: responsive(2rem, 3rem);
    grid-template-columns: repeat(auto-fill, minmax(min(19rem, 100%), 1fr));
    margin-top: responsive(4rem, 8rem);

    @media (min-width: $min-960) {
      grid-template-columns: repeat(auto-fill, minmax(min(24rem, 100%), 1fr));
    }
  }

  &__list {
    @include reset-list;
  }
}

.item {
  position: relative;
  overflow: hidden;
  max-width: 42rem;
  box-shadow: 0 2rem 7.7rem rgba(#000, 0.07);

  &__link {
    @include reset-anchor;
  }

  &__layout {
    display: grid;
    grid-template-rows: auto auto 0;
  }

  &__image {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 0 responsive(1.5rem, 3rem) responsive(1.5rem, 3rem);
  }

  &__aspect {
    position: relative;
    width: 100%;
    max-height: 100%;
    aspect-ratio: 4 / 3;
    transition: max-height $transition-leave;

    @supports not (aspect-ratio: 1 / 1) {
      &::before {
        float: left;
        padding-top: 100%;
        content: '';
      }

      &::after {
        display: block;
        content: '';
        clear: both;
      }
    }
  }

  &__content {
    padding: responsive(1.5rem, 3rem) responsive(1.5rem, 3rem) 0;
    transform: translateY(0);
    transition: $transition-leave;
    background: rgba(#fff, 0.9);

    :global(body.dark) & {
      background: rgba(#232323, 0.9);
    }

    :global(body.brand) & {
      background: brand(0.85);
    }
  }

  // &:is(:hover, :focus-visible) &__content {
  //   transform: translateY(-10rem);
  //   transition: transform $transition-enter;
  // }

  &__title {
    @include tagline;
    @include trailingLine(1.8rem);
    // color: $color-copy;
    margin-bottom: 0.7rem;
  }

  &__copy {
    @include render-copy;
  }

  &__button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 3rem 4.5rem;
    height: 10rem;
    font-size: 1.2rem;
    letter-spacing: 0.08em;
    font-weight: 700;
    font-family: $font-family;
    text-transform: uppercase;
    background: brand();
    transform: translateY(100%);
    transition: $transition-leave;

    svg {
      width: 1.1rem;
      height: 1.8rem;
    }

    :global(body.brand) & {
      background: $color-dark;
      color: #fff;

      svg path {
        fill: #fff;
      }
    }
  }

  &:is(:hover, :focus-visible) &__button {
    transform: translateY(0);
    transition: $transition-enter;
  }
}
