@use 'styles/config' as *;
@use "sass:math";

.simpleList {
  &__table {
    width: 100%;
    border-collapse: collapse;
  }

  thead {
    @media (max-width: $max-720) {
      display: none;
    }
  }

  &__label {
    padding: 2rem 1rem;
    min-height: 1.8rem;

    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
    letter-spacing: math.div(0.5, 12) * 1em;
    color: $color-font-light;

    user-select: none;

    &:first-child {
      padding-left: responsive(0rem, 3rem);
    }

    &:last-child {
      // text-align: right;
      padding-right: responsive(2.1rem, 3rem);
    }

    &Layout {
      display: inline-flex;
      align-items: center;
      column-gap: 1rem;
    }

    &:last-child &Layout {
      margin-right: -2.1rem;
    }
  }

  &__indicator {
    display: block;
    opacity: 0;
    width: 1.1rem;
    height: 1.8rem;

    svg {
      display: block;
      width: 1.1rem;
      height: 1.8rem;
    }

    .sorted & {
      opacity: 1;
      transform: rotate(90deg) scale(0.8);
    }

    .descending & {
      transform: rotate(-90deg) scale(0.8);
    }
  }

  &__item {
    @media (max-width: $max-720) {
      display: flex;
      row-gap: 1em;
      column-gap: 1em;
      flex-wrap: wrap;
      padding: 2rem 0;
      border-top: 1px solid $color-border;
    }
  }

  &__value {
    @media (max-width: $max-720) {
      display: block;
      flex: 1 1 45%;
      min-width: 10rem;

      &:not(:first-child)::before {
        content: attr(data-label);
        display: block;
        font-size: 1.2rem;
        font-weight: 600;
        color: $color-font-light;
      }

      &.name {
        flex: 1 1 100%;
      }

      // &:last-child {
      //   flex: 1 1 100%;
      // }
    }

    @media (min-width: $min-720) {
      padding: 2rem 1rem;
      font-size: responsive(1.4rem, 1.7rem);
      border-top: 1px solid $color-border;
      vertical-align: top;

      &.name {
        font-weight: 600;
      }

      &:first-child {
        padding-left: responsive(0rem, 3rem);
      }

      &:last-child {
        // text-align: right;
        padding-right: responsive(2.1rem, 3rem);
      }

      &:not(:first-child) {
        max-width: 20rem;
      }

      a {
        @include anchor;
      }
    }
  }

  &__item:last-child &__value,
  &__links:last-child &__value {
    border-bottom: 1px solid $color-border;
  }

  &__links &__value {
    border-top: 0;

    @media (min-width: $min-720) {
      background-color: #fafafa;
    }

    @media (max-width: $max-720) {
      padding: 0 0 2rem;
    }

    a {
      @include anchor;
      display: flex;
      align-items: center;
      gap: 0.8em;
      text-decoration: none;
      font-weight: 600;
      font-size: 0.85em;

      svg {
        height: 1em;
      }
    }
  }

  &__empty {
    padding: 5rem;
    font-weight: 400;
    color: $color-copy;
    text-align: center;
  }
}
