@use 'styles/config' as *;

.table {
  &.spacing {
    margin-top: $gutter * 2;
  }
}

.row {
  display: flex;
  justify-content: space-between;

  margin: 0 0 -0.1rem;
  padding: 1.3rem 0rem;

  line-height: 1.66;

  border: 0.1rem none $color-border;
  border-style: solid none;

  opacity: 0;
  transform: translateY(1.5rem);

  &__col {
    @include render-copy;

    margin: 0 1rem;

    &:last-child {
      font-weight: 600;
      color: brand();
    }
  }
}
