@use 'styles/config' as *;

.accordion {
  &__segment {
    @include segment;
  }

  &.hasImage:first-child &__segment {
    @include segment-top(5rem, 0rem);
  }

  &__container {
    @include container;
  }

  &__text {
    @include render-copy;
    margin-top: responsive(-5rem, -4rem);
    margin-bottom: responsive(5rem, 11rem);

    @media (min-width: $min-720) {
      @include grid-col(8);
      @include grid-offset-left(2);
    }
  }

  &__indent {
  }
}

.item {
  border-top: 1px solid $color-border;

  &:last-child {
    border-bottom: 1px solid $color-border;
  }

  &__head {
    @include reset-button;
    display: flex;
    gap: 1rem;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    padding: 2rem responsive(0rem, 3rem);
    cursor: pointer;

    p {
      margin: 0;
      padding: 0;
      font-weight: 600;

      & > :global(.Subscript) {
        vertical-align: -10%;
        font-size: max(0.7em, 1.2rem);
      }

      & > :global(.Superscript) {
        vertical-align: 10%;
        font-size: max(0.7em, 1.2rem);
      }
    }
  }

  &__body {
    box-shadow: inset 0 1px 0 0 $color-border;
    overflow: hidden;
    height: 0;
    background-color: #fafafa;
  }

  &__title {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 1rem;
  }

  &__icon {
    display: block;

    svg {
      display: block;
    }
  }

  &__arrow {
    width: 1.1rem;
    transition: transform 0.3s;
    transform: rotate(-270deg);

    .open & {
      transform: rotate(-90deg);
    }

    svg {
      display: block;
    }
  }

  &__copy {
    @include reset-all;
    @include render-copy();
    padding: 3rem;

    p {
      color: #757575;
    }

    :global(.Superscript) {
      vertical-align: super;
      font-size: max(0.7em, 1.2rem);
    }

    :global(.Footnote) {
      font-size: max(0.8em, 1.2rem);
    }
  }
}
