@use 'styles/config' as *;
@use "sass:math";

.table {
  &__table {
    width: 100%;
  }

  &__label {
    padding: 2rem 0;
    min-height: 1.8rem;

    font-size: responsive(1.2rem, 1.2rem);
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
    letter-spacing: math.div(0.5, 12) * 1em;
    color: $color-font-light;

    user-select: none;

    &:first-child {
      padding-left: responsive(0rem, 3rem);
    }

    &:last-child {
      text-align: right;
      padding-right: responsive(2.1rem, 3rem);
    }

    &Layout {
      display: inline-flex;
      align-items: center;
      column-gap: 1rem;
    }

    &:last-child &Layout {
      margin-right: -2.1rem;
    }
  }

  &__indicator {
    display: block;
    opacity: 0;
    width: 1.1rem;
    height: 1.8rem;

    svg {
      display: block;
      width: 1.1rem;
      height: 1.8rem;
    }

    .sorted & {
      opacity: 1;
      transform: rotate(90deg) scale(0.8);
    }

    .descending & {
      transform: rotate(-90deg) scale(0.8);
    }
  }

  &__value {
    padding: 2rem 0;
    font-size: responsive(1.4rem, 1.7rem);
    border-top: 1px solid $color-border;

    &.name {
      font-weight: 600;
    }

    &:first-child {
      padding-left: responsive(0rem, 3rem);
    }

    &:last-child {
      text-align: right;
      padding-right: responsive(2.1rem, 3rem);
    }
  }

  &__item:last-child &__value {
    border-bottom: 1px solid $color-border;
  }
}
