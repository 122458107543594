@use 'styles/config' as *;
@use "sass:math";

.portal {
  &__segment {
    @include segment-top;
    @include segment-bottom;
  }

  // &:not(:first-child) &__segment {
  // }

  &__container {
    @include container;
  }

  &__content {
    margin-top: responsive(-5rem, -7rem);
  }

  &__list {
    @include segment-top;
    display: grid;
    column-gap: 3rem;
    row-gap: responsive(5rem, 7rem);

    @media (min-width: $min-840) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.item {
  @include reset-anchor;
  display: grid;
  align-content: flex-start;
  justify-content: flex-start;
  max-width: 50rem;

  &:nth-child(2n) {
    justify-self: flex-end;
  }

  &__image {
    position: relative;
    width: 100%;
    aspect-ratio: 4/3;

    margin-bottom: responsive(2.5rem, 4.5rem);

    * {
      vertical-align: top;
    }
  }

  &__content {
    @media (min-width: $min-960) {
      padding-left: responsive(4rem, 4.5rem);
      padding-right: responsive(0rem, 3rem);
    }
  }

  &__title {
    @include h5(2rem, 2rem);
    margin-bottom: 1.5rem;
    font-weight: 600;
    transition: color 0.2s ease-in-out;
  }

  &:hover &__title {
    color: brand();
  }

  &__text {
    margin-bottom: responsive(2rem, 4.5rem);

    p {
      @include copy;
    }
  }

  &__link {
  }
}

.button {
  display: inline-grid;
  align-items: center;
  justify-content: flex-start;
  grid-auto-flow: column;
  grid-template-columns: responsive(4rem, 4.5rem) auto;
  position: relative;

  font-size: responsive(1.3rem, 1.6rem);
  font-weight: 600;
  color: $color-font;
  letter-spacing: math.div(0.2, 16) * 1em;
  text-align: left;

  cursor: pointer;

  svg {
    fill: brand();
    width: 1.1rem;
    height: 1.125em;

    transition-property: fill, transform;
    transition-duration: $transition-enter;
  }

  &__text {
  }

  .item:hover & svg {
    transform: translateX(1rem);
  }
}
