@use 'styles/config' as *;
@use "sass:math";

.articlesRelated {
  position: relative;
  order: 1;
  max-width: 45rem;
  margin: 0 auto;

  @media (min-width: $min-960) {
    width: 50%;
    max-width: none;
    margin: 0;
    padding-top: 4rem;
    padding-left: responsive(1rem, 5rem);
    order: 0;
  }

  &__sections {
    background: brand();
    padding: responsive(2rem, 7rem) responsive(2rem, 7rem) 10rem;

    :global(.adalvo) & {
      color: brandFont();
    }

    @media (min-width: $min-960) {
      margin-right: 7.5rem;
    }
  }

  &__section {
    & + & {
      margin-top: 6rem;
    }
  }

  &__title {
    @include tagline;

    margin-bottom: 3rem;
    font-size: 1.4rem;
  }

  &__text {
    margin-bottom: 2rem;
    font-size: 1.4rem;

    > h3 {
      // margin-top: 0;
      margin-bottom: 0.5rem;

      &:first-child {
        margin-top: 0;
      }
    }
    > p {
      margin: 0 0 1rem;
    }

    > ul {
      @include reset-list;

      display: flex;
      flex-wrap: wrap;

      li {
        margin: 0.6rem 0;

        &:not(:last-child) {
          margin-right: 3rem;
        }
      }

      a {
        @include reset-anchor();

        display: inline-flex;
        align-items: center;
        position: relative;

        font-size: responsive(1.3rem, 1.6rem);
        font-weight: 600;
        color: $color-font;
        letter-spacing: math.div(0.2, 16) * 1em;
        white-space: nowrap;

        cursor: pointer;

        transition-property: background-color, color;
        transition-duration: 200ms;

        :global(.adalvo) & {
          color: brandFont();
        }

        &::before {
          content: '';
          display: flex;
          justify-content: center;
          margin-right: responsive(1.5rem, 2rem);

          background: url('/static/icons/chevron-right.svg') no-repeat;
          width: 1.1rem;
          height: 1.8rem;
          transition: transform 0.2s ease-in-out;

          :global(.adalvo) & {
            background-image: url('/static/icons/chevron-right-white.svg');
          }
        }

        &:hover::before {
          transform: translateX(1rem);
        }
      }
    }
  }

  &__button {
    position: relative;
    margin-top: -5rem;

    @media (min-width: $min-1024) {
      margin-left: 7rem;
      margin-right: -2rem;
    }
  }
}
