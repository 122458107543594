@use 'styles/config' as *;

.packages {
  @include segment;

  &__container {
    @include container;
  }

  &__header {
    margin-bottom: 3rem;

    @media (min-width: $min-840) {
      margin-bottom: 6rem;
    }
  }

  &__title {
    @include h2;
    // margin-bottom: 3rem;

    :global(body.dark) & {
      color: #fff;
    }

    @media (min-width: $min-840) {
      margin-bottom: 6rem;
    }
  }
}
