@use 'styles/config' as *;

.small {
  @include segment-bottom;

  &.hasText {
    @include segment-top;
  }

  &__container {
    @include container;
  }

  &__row {
    @media (min-width: $min-960) {
      @include grid-row;
    }
  }

  &__layout {
    @media (min-width: $min-960) {
      @include grid-col(10);
      @include grid-offset-left(1);
    }
  }

  &__disclaimer {
    @include segment-bottom;
  }

  &__heading {
    h1,
    h2,
    h3 {
      @include h2;
      margin-bottom: 1em;
      color: #757575;
    }
  }

  &__text {
    @include render-copy(1.3rem, 1.35rem);
    color: #757575;
  }

  &__footnotes {
    @include segment-top;
    border-top: 1px solid $color-border;
  }

  &__list {
    @include reset-list;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    counter-reset: footnote-counter;
  }
}

.footnote {
  @include reset-all;
  display: grid;
  column-gap: 1rem;
  align-items: baseline;
  grid-template-columns: auto 1fr;

  font-size: 1.3rem;
  color: $color-copy;

  > * {
    grid-column: 2 / 3;
  }

  &::before {
    content: attr(data-symbol);
    grid-column: 1 / 2;
  }

  &[data-symbol] {
    list-style-type: none;

    &::before {
      content: attr(data-symbol);
      font-size: 1.2em;
    }

    // safari sucks
    // @supports selector(::marker) {
    //   &::marker {
    //     content: attr(data-symbol);
    //   }
    // }

    // @supports not selector(::marker) {
    //   &::before {
    //     content: attr(data-symbol);
    //   }
    // }
  }

  &:not([data-symbol]) {
    counter-increment: footnote-counter;
    list-style-type: none;

    &::before {
      content: counter(footnote-counter) '.';
      // font-size: 1.2rem;
    }

    // safari sucks
    // @supports selector(::marker) {
    //   &::marker {
    //     content: counter(footnote-counter) '.';
    //   }
    // }

    // @supports not selector(::marker) {
    //   &::before {
    //     content: counter(footnote-counter) '.';
    //   }
    // }
  }

  p {
    @include reset-paragraph;
    @include copy(1.3rem, 1.3rem);

    a {
      @include anchor;
      color: $color-copy;
    }
  }
}
