@use 'styles/config' as *;

.quarters {
  &__segment {
    @include segment;
  }

  &__container {
    @include container;
  }

  &__header {
    display: grid;
    align-items: flex-end;
    column-gap: 3rem;
    row-gap: responsive(3rem, 6rem);
    grid-template-columns: 1fr;
    margin-bottom: responsive(6rem, 8rem);

    @media (min-width: $min-840) {
      grid-template-columns: 1fr auto;
    }
  }

  &__headlines {
    grid-column: 1 / 2;
  }

  &__actions {
    grid-column: 1 / 2;

    @media (min-width: $min-840) {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }
  }

  &__text {
    @include render-copy;
    max-width: 67.5rem;

    grid-column: 1 / 2;
    grid-row: 2 / 3;

    @media (min-width: $min-840) {
      grid-column: 1 / 3;
      grid-row: 2 / 3;
    }
  }

  &__tagline {
    @include taglineWithLine;

    margin: 0 0 4rem;
  }

  &__title {
    @include h1;
    margin: 0;
    max-width: 67.5rem;

    :global(body.dark) & {
      color: #fff;
    }
  }

  &__grid {
    display: block;
    box-shadow: 0 2rem 7.7rem rgba(#000, 0.07);
  }
}

.year {
  &:not(:last-child) {
    border-bottom: 1px solid $color-border;
  }

  &__layout {
    position: relative;
    display: flex;
    flex-direction: column;

    @media (min-width: $min-600) {
      flex-direction: row;
    }
  }

  &__header {
    flex: 0 0 7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: responsive(3rem, 2rem) responsive(2rem, 3rem);

    .collapsed & {
      cursor: pointer;
    }

    @media (min-width: $min-600) {
      flex: 0 0 15.7rem;
      // justify-content: center;
      border-bottom: 0;
      border-right: 1px solid $color-border;
    }
  }

  &__title {
    @include h4(2.7rem, 3.5rem);
    transition: font-size 300ms;

    @media (min-width: $min-600) {
      .collapsed & {
        @include h4;
      }
    }
  }

  &__toggle {
    @include reset-button;
    transform: rotate(-90deg);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    margin-bottom: -1rem;
    margin-top: -1rem;
    cursor: pointer;
    z-index: 1;

    &:is(&:hover, &:focus-visible) {
      svg path {
        fill: brand();
      }
    }

    svg {
      display: block;
      width: 1.1rem * 0.75;
      height: 1.8rem * 0.75;
    }

    .collapsed & {
      transform: rotate(90deg);
    }

    @media (min-width: $min-600) {
      position: absolute;
      top: 3.1rem;
      right: 1rem;
      width: 4rem;
      height: 4rem;
    }
  }

  &__grid {
    overflow: hidden;
    flex: 1 1 auto;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    .collapsed & {
      opacity: 0.5;
    }

    @media (max-width: $max-600) {
      border-top: 1px solid $color-border;

      .collapsed & {
        display: none;
      }
    }

    @media (min-width: $min-1080) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}

.quarter {
  overflow: hidden;
  padding: responsive(1rem, 2rem) responsive(2rem, 3rem) responsive(1.7rem, 2.7rem);
  box-shadow: 1px 1px 0 0 $color-border;
  transition: padding 300ms;

  .collapsed & {
    padding: responsive(1rem, 2rem) responsive(2rem, 3rem);
  }

  &__title {
    @include tagline;
    @include trailingLine(2rem);
    letter-spacing: 1px;
    color: $color-copy;
  }

  &__list {
    @include reset-list;
    overflow: hidden;
    max-height: 15rem;
    transition: max-height 300ms;

    .collapsed & {
      max-height: 0;
    }
  }

  &__item {
  }

  &__link {
    @include anchor;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
  }
}
