@use 'styles/config' as *;

.variant {
  $color-border: #d6d6d6;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;

  @media (min-width: $min-720) {
    margin-bottom: 8rem;
  }

  @media (min-width: $min-600) {
    flex-direction: row;
  }

  &__image {
    flex: 0 0 auto;
    display: flex;
    order: -1;
    width: 15rem;
    max-height: 10rem;
    margin-bottom: 3rem;
    // overflow: hidden;

    @media (min-width: $min-600) {
      order: 0;
      margin-left: $gutter;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top left;
  }

  &__table {
    flex: 1 1 auto;
  }

  &__row {
  }

  &__header,
  &__cell {
    font-size: responsive(1.4rem, 2rem);
    padding: 1rem 2rem;
    text-align: left;
    border-bottom: 1px solid $color-border;

    &:first-child {
      padding-left: 0;
    }

    @media (min-width: $min-720) {
      padding: 1rem 2rem;

      &:first-child {
        padding-left: 2rem;
      }
    }
  }

  &__row:first-child &__header,
  &__row:first-child &__cell {
    border-top: 1px solid $color-border;
  }
}
