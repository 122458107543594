@use 'styles/config' as *;

.search {
  &__segment {
    @include segment;
  }

  &__container {
    @include container();
  }

  &__header {
    margin-bottom: 6rem;

    @media (min-width: $min-720) {
      margin-bottom: 12rem;
    }
  }

  &__label {
    @include tagline;
  }

  &__input {
    @include reset-input;
    @include h2;
    max-width: 100%;
  }

  &__list {
    // grid-template-columns: repeat(1, minmax(0, 1fr));

    @media (min-width: $min-720) {
      display: grid;
      grid-auto-flow: dense;
      grid-column-gap: $gutter * 2;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (min-width: $min-1200) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}

.result {
  &__box {
    overflow: hidden;
    padding-bottom: 6rem;
    max-width: 48rem;
    margin: auto;

    @media (min-width: $min-720) {
      padding-bottom: 8rem;
    }
  }

  &__link {
    @include reset-anchor;
    display: block;
    overflow: hidden;
  }

  &:nth-child(2) &__link {
    @media (min-width: $min-720) {
      padding-top: 10rem;
    }
  }

  &__image {
    position: relative;
    overflow: hidden;
    margin-bottom: 2rem;

    * {
      vertical-align: top;
    }

    @media (min-width: $min-720) {
      margin-bottom: 4.5rem;
    }

    &::after {
      content: '';
      position: absolute 0;
      pointer-events: none;
      box-shadow: inset 0 0 3rem 0 rgba(#000, 0.05);
    }
  }

  &__tagline {
    @include tagline;
  }

  &__title {
    @include h4;
    margin-top: -1rem;
    transition: color 500ms;

    @media (min-width: $min-720) {
      margin-top: -3rem;
    }
  }

  &:hover &__title {
    color: brand();
    transition: color 0ms;
  }
}

.noResult {
  grid-column: 1 / -1;
}
